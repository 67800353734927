<template>
  <v-card flat>
    <v-row no-gutters>
      <v-col cols="auto" class="pr-6">
        <v-tabs vertical v-model="tab" :color="tabColor">
          <v-tab v-for="(id, index) in ids" :key="index">
            {{ id }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(id, index) in ids" :key="index">
            <trial-card :studyId="id"></trial-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TrialCard from './TrialCard'

export default {
  props: {
    ids: {
      type: Array,
      default: Array.new
    },
    tabColor: String,
    visible: Boolean
  },
  data: () => ({
    tab: 0
  }),
  watch: {
    visible() {
      if (this.visible) {
        if (this.tab > 0) {
          this.tab = 0
        } else {
          this.track()
        }
      }
    },
    tab: {
      handler: 'track',
      immediate: true
    }
  },
  components: {
    TrialCard
  },
  methods: {
    track() {
      this.$emit('view-page', this.ids[this.tab])
    }
  }
}
</script>