<template>
  <v-card
    class="study-modal"
    :min-height="`${$vuetify.breakpoint.height - 64}px`"
  >
    <v-card-text class="study-model-body">
      <div class="study-area study-title">
        <span v-show="study.adult">Adult&nbsp;</span>
        <span v-show="study.adult && study.pediatric">and&nbsp;</span>
        <span v-show="study.pediatric">Pediatric&nbsp;</span>
        Study – Title:&nbsp;
        <span v-html="study.trialName"></span>
      </div>

      <v-divider class="my-2"></v-divider>

      <div class="study-area"><strong>Status:</strong> {{ study.status }}</div>
      <div class="study-area">
        <strong>Purpose:</strong> <span v-html="study.purpose"></span>
      </div>
      <div class="study-area" v-if="study.sites.length">
        <strong>Study Sites:</strong>
        {{ study.sites.join("; ") }}
      </div>

      <v-divider class="my-2"></v-divider>

      <div class="study-area">
        <strong v-show="!study.primary.customTitle">
          <span v-show="study.primary.isKey">Key&nbsp;</span>
          Primary Outcome Measures<span v-show="study.primary.additionalInfo"
            >*</span
          >:
        </strong>
        <strong v-show="study.primary.customTitle">
          <span v-html="study.primary.customTitle"></span>
        </strong>
        <br />
        <ul>
          <li
            v-for="(outcome, index) in study.primary.outcomes"
            :key="index"
            v-html="outcome"
          ></li>
        </ul>
      </div>
      <div class="study-area" v-if="study.secondary.outcomes.length">
        <strong v-show="!study.secondary.customTitle">
          <span v-show="study.secondary.isKey">Key&nbsp;</span>
          Secondary/Exploratory Outcome Measures<span
            v-show="study.secondary.additionalInfo"
            >*</span
          >:
        </strong>
        <strong v-show="study.secondary.customTitle">
          <span v-html="study.secondary.customTitle"></span>
        </strong>
        <br />
        <ul>
          <li
            v-for="(outcome, index) in study.secondary.outcomes"
            :key="index"
            v-html="outcome"
          ></li>
        </ul>
      </div>

      <v-divider class="my-2"></v-divider>

      <div class="study-area">
        <strong>Study Arms:</strong><br />
        <ul>
          <li
            v-for="(criteria, index) in study.arms"
            :key="index"
            v-html="criteria"
          ></li>
        </ul>
      </div>

      <v-divider class="my-2"></v-divider>

      <div class="study-area">
        <strong>Key Eligibility Criteria:</strong><br />
        <ul>
          <li
            v-for="(criteria, index) in study.eligibilityCriteria"
            :key="index"
            v-html="criteria"
          ></li>
        </ul>
      </div>

      <div
        class="study-area"
        v-show="study.primary.additionalInfo || study.secondary.additionalInfo"
        v-html="additionalInfoMsg"
      ></div>
      <div class="study-area" v-html="contactInfoMessage"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import trials from "./../trials.json";

export default {
  name: "TrialCard",
  props: {
    studyId: {
      type: String,
      required: true,
    },
  },
  watch: {
    studyId: {
      handler() {
        this.study = trials.studies.find(
          (study) => study.identifier === this.studyId
        );
      },
      immediate: true,
    },
  },
  data: () => ({
    study: {},
    additionalInfoMsg: "*Additional measures listed on clinicaltrials.gov.",
  }),
  computed: {
    contactInfoMessage() {
      return `For more information, please visit ClinicalTrials.gov (Identifier: ${this.study.identifier})`;
    },
  },
};
</script>

<style scoped lang="scss">
.study {
  &-model {
    &-body {
      padding: 24px 50px;
    }
  }
  &-area {
    margin-bottom: 12px;
    &-note {
      border: 4px solid yellow;
      padding: 16px;
    }
  }
  &-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 6px;
  }
}
</style>
